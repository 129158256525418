import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   products: [],
   totalPage: 0,
   isLoading: false,
}

const productSlice = createSlice({
   name: 'productSlice',
   initialState,
   reducers: {
      setProducts(state, { payload }) {
         state.products = payload.products
         state.totalPage = payload.totalPage
         state.isLoading = false
      },
      setIsLoading(state, { payload }) {
         state.isLoading = payload
      },
   },
})

export const productActions = productSlice.actions

export default productSlice
