import { createSlice } from '@reduxjs/toolkit'

const initialState = { businessAreas: [], isLoading: false }

const businessAreaSlice = createSlice({
   name: 'businessAreaSlice',
   initialState,
   reducers: {
      getBusinessAreas(state, { payload }) {
         state.businessAreas = payload.businessAreas
         state.isLoading = false
      },
      setIsLoading(state, { payload }) {
         state.isLoading = payload
      },
   },
})

export const businessAreaActions = businessAreaSlice.actions

export default businessAreaSlice
