import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   isAuthorized: false,
   accessToken: '',
   loginError: false,
   signInError: false,
   role: null,
   isLoading: false,
   language: 'ru',
}

const userSlice = createSlice({
   name: 'userSlice',
   initialState,
   reducers: {
      logIn(state, { payload }) {
         state.accessToken = payload.accessToken
         state.role = payload.role
         state.isAuthorized = true
         state.loginError = false
      },
      logInLoading(state, { payload }) {
         state.isLoading = payload
      },
      logOut() {
         return initialState
      },
      errorLogin(state, { payload }) {
         state.loginError = payload.loginError
      },
      setLanguage(state, { payload }) {
         state.language = payload
      },
   },
})

export const userActions = userSlice.actions

export default userSlice
