import React, { lazy } from 'react'
import { bool } from 'prop-types'
import { Route, Routes } from 'react-router-dom'
import { ROUTES } from '../utils/constants/general'
import PrivateAuthRoute from './PrivateAuthRoute'

const LandingPage = lazy(() => import('../containers/LandingPage/Index'))
const DashboardRoutes = lazy(() => import('./DashboardRoutes'))
const ResetPassword = lazy(() => import('../containers/auth/ResetPassword'))

export default function MainRouter({ isAuthorized }) {
   return (
      <Routes>
         <Route
            path={ROUTES['MAIN-PAGE']}
            element={
               <PrivateAuthRoute
                  component={<LandingPage />}
                  isAllowed={!isAuthorized}
                  fallbackPath={ROUTES.DASHBOARD}
               />
            }
         />
         <Route path={ROUTES['PASSWORD-RESET']} element={<ResetPassword />} />
         <Route
            path={`${ROUTES.DASHBOARD}/*`}
            element={
               <PrivateAuthRoute
                  component={<DashboardRoutes />}
                  isAllowed={isAuthorized}
                  fallbackPath={ROUTES['MAIN-PAGE']}
               />
            }
         />
         <Route
            path={ROUTES['NOT-FOUND']}
            element={
               <div
                  style={{
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     height: '100vh',
                  }}
               >
                  <div>
                     <h1 style={{ fontSize: '200px' }}>404</h1>
                     <p style={{ fontSize: '50px' }}>Page not found</p>
                  </div>
               </div>
            }
         />
      </Routes>
   )
}

MainRouter.propTypes = {
   isAuthorized: bool.isRequired,
}
