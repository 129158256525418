import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   clients: [],
   isLoading: false,
   totalActiveClients: 0,
   totalArchiveClients: 0,
   totalPage: 1,
   invoiceClient: [],
}

const clientSlice = createSlice({
   name: 'clientSlice',
   initialState,
   reducers: {
      setClients(state, { payload }) {
         state.clients = payload.clients
         state.totalPage = payload.totalPage || 1
         state.totalActiveClients = payload.totalActiveClients
         state.totalArchiveClients = payload.totalArchiveClients
         state.isLoading = false
      },
      setInvoiceClient(state, { payload }) {
         state.invoiceClient = payload.clients
         state.totalPage = payload.totalPage || 1
         state.isLoading = false
      },
      setIsLoading(state, { payload }) {
         state.isLoading = payload
      },
   },
})

export const clientActions = clientSlice.actions

export default clientSlice
