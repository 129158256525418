import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   editors: [],
   isLoading: false,
   currency: '',
}

const editorSlice = createSlice({
   name: 'editorSlice',
   initialState,
   reducers: {
      setEditors(state, { payload }) {
         state.currency = payload.editors[0].company_name.currency
         state.editors = payload.editors
         state.isLoading = false
      },
      setCurrency(state, { payload }) {
         state.currency = payload
      },
      setIsLoading(state, { payload }) {
         state.isLoading = payload
      },
   },
})

export const editorActions = editorSlice.actions

export default editorSlice
