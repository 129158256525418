import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   categories: [],
   expenseCategories: [],
   isLoading: false,
}

const categorySlice = createSlice({
   name: 'categorySlice',
   initialState,
   reducers: {
      setCategories(state, { payload }) {
         state.categories = payload.categories
         state.isLoading = false
      },
      setExpenseCategories(state, { payload }) {
         state.expenseCategories = payload.categories
         state.isLoading = false
      },
      setIsLoading(state, { payload }) {
         state.isLoading = payload
      },
   },
})

export const categoryActions = categorySlice.actions

export default categorySlice
