export const GLOBAL_STYLES = {
   span: {
      fontFamily: 'Gilroy',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '130%',
   },
   p: {
      fontFamily: 'Gilroy',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '18px',
      lineHeight: '22px',
   },

   h2: {
      fontFamily: 'Gilroy',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '32px',
      lineHeight: '39px',
   },

   h1: {
      fontFamily: 'Gilroy',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '40px',
      lineHeight: '49px',
      textAlign: 'center',
      textTransform: 'uppercase',
   },

   li: {
      fontFamily: 'Gilroy-bold',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '19px',
   },

   h3: {
      fontFamily: 'Gilroy',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '24px',
      lineHeight: '29px',
   },
   'input::webkitOuterSpinButton': {
      webkitAppearance: 'none',
      margin: 0,
      webkitTextFillColor: '#707070',
   },
   'input::webkitInnerSpinButton': {
      webkitAppearance: 'none',
      margin: 0,
      webkitTextFillColor: '#707070',
   },

   'input::placeholder': {
      opacity: 'unset !important',
   },

   '*': {
      boxSizing: 'border-box',
   },
   a: {
      textDecoration: 'none',
   },
}
