import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   tags: [],
   allTags: [],
   isLoading: false,
}

const tagSlice = createSlice({
   name: 'tagSlice',
   initialState,
   reducers: {
      setIsLoading(state, { payload }) {
         state.isLoading = payload
      },
      setTags(state, { payload }) {
         state.tags = payload.tags
      },
      setAllTags(state, { payload }) {
         state.allTags = payload.tags
      },
   },
})

export const tagActions = tagSlice.actions

export default tagSlice
