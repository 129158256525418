import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   bankAccounts: [],
   isLoading: false,
}

const bankAccountSlice = createSlice({
   name: 'bankAccountSlice',
   initialState,
   reducers: {
      setBankAccounts(state, { payload }) {
         state.bankAccounts = payload.bankAccounts
         state.isLoading = false
      },
      setIsLoading(state, { payload }) {
         state.isLoading = payload
      },
   },
})

export const bankAccountActions = bankAccountSlice.actions

export default bankAccountSlice
