import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   transactions: [],
   isLoading: false,
   cashInFlow: 0,
   cashOutFlow: 0,
   netAmount: 0,
   totalPage: 1,
}

const transactionSlice = createSlice({
   name: 'transactionSlice',
   initialState,
   reducers: {
      setTransactions(state, { payload }) {
         state.transactions = payload.transactions
         state.totalPage = payload.totalPage
         state.cashInFlow = payload.cash_in_flow
         state.cashOutFlow = payload.cash_out_flow
         state.netAmount = payload.net_amount
         state.isLoading = false
      },
      setIsLoading(state, { payload }) {
         state.isLoading = payload
      },
   },
})
export const transactionActions = transactionSlice.actions

export default transactionSlice
