import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   checks: [],
   isLoading: false,
   countOfInvoce: 0,
   totalPage: 1,
}

const checkSlice = createSlice({
   name: 'checkSlice',
   initialState,
   reducers: {
      setChecks(state, { payload }) {
         state.checks = payload.checks
         state.totalPage = payload.totalPage
         state.isLoading = payload
         state.countOfInvoce = payload.countOfInvoce || 0
      },
      setIsLoading(state, { payload }) {
         state.isLoading = payload
      },
   },
})

export const checkActions = checkSlice.actions

export default checkSlice
