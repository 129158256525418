import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   sellers: [],
   sellerWithCheck: [],
   isLoading: false,
   totalPage: 1,
   totalActiveSellers: 0,
   totalArchiveSellers: 0,
}
const sellersSlice = createSlice({
   name: 'sellersSlice',
   initialState,
   reducers: {
      setSellers(state, { payload }) {
         state.sellers = payload.sellers
         state.totalActiveSellers = payload.totalActiveSellers
         state.totalArchiveSellers = payload.totalArchiveSellers
         state.totalPage = payload.totalPage
         state.isLoading = false
      },
      setSellersWithCheck(state, { payload }) {
         state.sellerWithCheck = payload.data
         state.totalPage = payload.totalPage
         state.isLoading = false
      },
      setIsLoading(state, { payload }) {
         state.isLoading = payload.isLoading
      },
   },
})

export const sellersActions = sellersSlice.actions
export default sellersSlice
