import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   payments: [],
   isLoading: false,
}

const paymentSlice = createSlice({
   name: 'paymentSlice',
   initialState,
   reducers: {
      setPayments(state, { payload }) {
         state.payments = payload.payments
         state.isLoading = false
      },
      setIsLoading(state, payload) {
         state.isLoading = payload
      },
   },
})

export const paymentActions = paymentSlice.actions

export default paymentSlice
