import { configureStore } from '@reduxjs/toolkit'
import businessAreaSlice from './businessArea/businessAreaSlice'
import categorySlice from './category/categorySlice'
import checkSlice from './costs/check/checkSlice'
import expenseProductSlice from './costs/product/expenseProductSlice'
import sellersSlice from './costs/sellers/sellersSlice'
import landingSlice from './landing/landingSlice'
import transactionSlice from './reports/transactions/transactionSlice'
import clientSlice from './sales/clients/clientSlice'
import productSlice from './sales/product/productSlice'
import tagSlice from './tag/tagSlice'
import userSlice from './user/userSlice'
import invoiceSlice from './sales/invoice/invoiceSlice'
import paymentSlice from './payment/paymentSlice'
import bankAccountSlice from './bankAccount/bankAccountSlice'
import editorSlice from './editor/editorSlice'
import chartSlice from './chart/chartSlice'
import reminderSlice from './reminder/reminderSlice'

const store = configureStore({
   reducer: {
      user: userSlice.reducer,
      businessArea: businessAreaSlice.reducer,
      landing: landingSlice.reducer,
      client: clientSlice.reducer,
      tag: tagSlice.reducer,
      product: productSlice.reducer,
      category: categorySlice.reducer,
      check: checkSlice.reducer,
      invoice: invoiceSlice.reducer,
      expenseProduct: expenseProductSlice.reducer,
      seller: sellersSlice.reducer,
      transaction: transactionSlice.reducer,
      payment: paymentSlice.reducer,
      bankAccount: bankAccountSlice.reducer,
      editor: editorSlice.reducer,
      chart: chartSlice.reducer,
      reminder: reminderSlice.reducer,
   },
})

export default store
