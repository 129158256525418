import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationEN from '../assets/locales/en/translation.json'
import translationRU from '../assets/locales/ru/translation.json'

const resources = {
   en: {
      translation: translationEN,
   },
   ru: {
      translation: translationRU,
   },
}
const fallbackLng = ['ru-RU']
const availableLanguages = ['en-GB', 'ru-RU']
i18n
   .use(LanguageDetector)
   .use(initReactI18next)
   .init({
      resources,
      fallbackLng,

      detection: {
         checkWhitelist: true,
      },
      debug: false,

      whitelist: availableLanguages,
      interpolation: {
         escapeValue: false,
      },
   })
export const { t } = i18n

export default i18n
