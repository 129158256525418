import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'

function Spinner({ size, ...props }) {
   return (
      <Box
         sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: "translate('-50%', '-50%')",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
         }}
         {...props}
      >
         <CircularProgress size={size} />
      </Box>
   )
}

Spinner.propTypes = {
   size: PropTypes.number.isRequired,
}

export default Spinner
