import { t } from '../../config/i18config'
import { ReactComponent as USIcon } from '../../assets/icons/USIcon.svg'
import { ReactComponent as USDIcon } from '../../assets/icons/currencyUSD.svg'
import { ReactComponent as RUIcon } from '../../assets/icons/RUIcon.svg'
import { ReactComponent as KGIcon } from '../../assets/icons/KGIcon.svg'
import { ReactComponent as EUROIcon } from '../../assets/icons/EUROIcon.svg'
import { ReactComponent as RUSIcon } from '../../assets/icons/RUSIcon.svg'
import { ReactComponent as SomIcon } from '../../assets/icons/somIcon.svg'

export const JWT_TOKEN_KEY = 'ACCOUNTING_JWT_TOKEN_KEY'
export const ROLE = 'ACCOUNTING_ROLE'

export const baseURL =
   process.env.REACT_APP_BACKEND_URL ||
   `http://accounting-dev.peaksoft.house/api/myaccount`

export const CURRENCY_ICONS = {
   USD: '$',
   KGS: <SomIcon />,
   EUR: '€',
   RUB: '₽',
}
export const CURRENCY_TEXT = {
   USD: '$',
   KGS: 'с',
   EUR: '€',
   RUB: '₽',
}

export const CURRENCY = [
   { text: 'KGS (C)', value: 'KGS', id: 1, icon: <KGIcon /> },
   { text: 'USD ($)', value: 'USD', id: 2, icon: <USDIcon /> },
   { text: 'EUR (€)', value: 'EUR', id: 3, icon: <EUROIcon /> },
   { text: 'RUB (₽)', value: 'RUB', id: 4, icon: <RUSIcon /> },
]
export const LANGUAGE = {
   'ru-RU': { text: 'Русский', icon: <RUIcon /> },
   en: { text: 'English', icon: <USIcon /> },
   'en-GB': { text: 'English', icon: <USIcon /> },
}
export const REQUEST_STATUSES = {
   TO_ACCEPT: {
      color: '#2CA803',
      text: t('sales.invoice.requestTable.accept'),
   },
   CONFIRMATION_REQUEST: {
      color: '#44779F',
      text: t('sales.invoice.requestTable.pending'),
   },
   REJECTED: {
      color: '#D65353',
      text: t('sales.invoice.requestTable.reject'),
   },
}
export const REQUEST_TYPES = {
   EDITING: t('sales.invoice.requestTable.edit'),
   REMOVAL: t('sales.invoice.requestTable.delete'),
}
export const REQUEST_TYPES_OF_MODAL = {
   EDITING: t('sales.invoice.requestTable.editRequest'),
   REMOVAL: t('sales.invoice.requestTable.deleteRequest'),
}
export const TYPES_OF_PAY = [
   { id: 'CASH', label: t('general.cash') },
   { id: 'BANK', label: t('general.bank') },
   { id: 'ELECTRONIC_MONEY_TRANSFER', label: t('general.electronic') },
]
export const ROUTES = {
   'MAIN-PAGE': '/main',
   DASHBOARD: '/dashboard',
   'PASSWORD-RESET': 'reset-password',
   INFOGRAPHICS: 'infographics',
   SALES: 'sales/*',
   CLIENTS: 'clients',
   PRODUCTS: 'products-and-services',
   INVOICE: 'invoice',
   COSTS: 'costs/*',
   COSTS_SELLERS: 'sellers',
   COSTS_SELLERS_INNER: 'sellers/seller/:id/*',
   COSTS_PRODUCTS: 'expenses',
   COSTS_CHECK: 'checks',
   COSTS_CREATE_CHECK: 'checks/create',
   COSTS_CHECK_INNER: 'checks/inner/:id',
   COSTS_CHECK_EDIT: 'checks/edit/:id',
   COSTS_PREVIEW: 'checks/preview/:id',
   REPORTS: 'reports/*',
   REPORTS_TRANSACTIONS: 'transactions',
   REPORTS_CHART: 'chart-of-reports',
   PROFILE: 'profile',
   CHILD_PROFILE: 'profile/:id',
   EDITOR: '/dashboard/editor/:id',
   'NOT-FOUND': '*',
}

export const EMAIL_PATTERN = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/

export const isStrHasNumbers = (str) => /\d/.test(str)

const errorMessages = (target) => ({
   maxLength: `${target} cannot be more than 256 letters`,
   required: `${target} cannot be empty`,
   custom: `${target} cannot contain digits or special characters`,
})

const errorAddressMessages = (target) => ({
   required: `${target} cannot be empty`,
   maxLength: `${target} cannot be more than 512 letters`,
   minLength: `${target} not less than 5 letters`,
})

export const validateFullName = (value, target) => {
   console.warn('validateFullName', value)
   if (!value) return errorMessages(target).required
   if (value.length > 256) return errorMessages(target).maxLength
   if (!isValidFullName(value)) return errorMessages(target).custom
   return false
}

export const validateAddress = (value, target) => {
   if (!value) return errorAddressMessages(target).required
   if (!value.length > 512) return errorAddressMessages(target).maxLength
   if (!isValidFullName(value)) return errorAddressMessages(target).minLength
   return true
}

export const isNumberValid = (num) => num.replace(/[^\d]/g, '').length >= 11
const isValidFullName = (str) => {
   return /^[^\d!@#$%^&*()_=+`~[\]{}|\\;:'",<.>/?]*$/.test(str)
}

export const USER_STATUS = {
   EXPIRED: { text: t('general.expired'), color: '#FF0000' },
   PAY_FOR: { text: t('general.payFor'), color: '#F89132' },
}
export const NOTIFICATION_TYPES_TEXT = {
   EDITING: t('general.permissionToEdit'),
   REMOVAL: t('general.permissionToDelete'),
}
export const NOTIFICATION_TYPES_FOR_CASHIER = {
   TO_ACCEPT: {
      EDITING: t('general.acceptToEdit'),
      REMOVAL: t('general.acceptToDelete'),
   },
   REJECTED: {
      EDITING: t('general.rejectToEdit'),
      REMOVAL: t('general.rejectToDelete'),
   },
}
export const validateUserData = (userData) => {
   const errors = {}

   Object.keys(userData).forEach((miniData) => {
      const hasError =
         validateData[miniData] && validateData[miniData].validation(userData)

      if (hasError) {
         errors[miniData] = validateData[miniData].error
         validateData[miniData].error = ''
      }
   })

   return errors
}

const validateData = {
   email: {
      validation(data) {
         if (!EMAIL_PATTERN.test(data.email)) {
            this.error = t('landingPage.signUp.emailValid')
            return true
         }
         return false
      },
      error: '',
   },
   name: {
      validation(data) {
         if (validateFullName(data.name)) {
            // this.error = t('landingPage.validation.nameNumber')
            this.error = validateFullName(data.name, 'Name')
            return true
         }
         return false
      },
      error: '',
   },
   firstName: {
      validation(data) {
         if (validateFullName(data.firstName)) {
            // this.error = t('landingPage.validation.nameNumber')
            this.error = validateFullName(data.firstName, 'First name')
            return true
         }
         return false
      },
      error: '',
   },
   lastName: {
      validation(data) {
         if (validateFullName(data.lastName)) {
            // this.error = t('landingPage.validation.lastNameNumber')
            this.error = validateFullName(data.lastName, 'Last name')
            return true
         }
         return false
      },
      error: '',
   },
   businessName: {
      validation(data) {
         if (validateFullName(data.businessName)) {
            this.error = validateFullName(data.businessName, 'Business name')
            return true
         }
         // if (!data.businessName.trim()) {
         //    this.error = t('landingPage.validation.requiredField')
         //    return true
         // }
         return false
      },
      error: '',
   },
   businessAddress: {
      validation(data) {
         if (validateAddress(data.businessAddress)) {
            // this.error = t('landingPage.validation.requiredField')
            this.error = validateAddress(
               data.businessAddress,
               'Business Address'
            )
            return true
         }
         // if (!data.businessAddress) {
         //    this.error = t('landingPage.validation.requiredField')
         //    return true
         // }
         return false
      },
      error: '',
   },
   phone: {
      validation(data) {
         if (!isNumberValid(data.phone || '')) {
            // this.error = t('landingPage.validation.phoneNumber')
            this.error = 'Please enter a valid phone number'
            return true
         }
         return false
      },
      error: '',
   },
   phone_number: {
      validation(data) {
         if (!isNumberValid(data.phone_number || '')) {
            // this.error = t('landingPage.validation.phoneNumber')
            this.error = 'Please enter a valid phone number'
            return true
         }
         return false
      },
      error: '',
   },
   newPassword: {
      validation(data) {
         if (!data.newPassword.trim()) {
            this.error = t('landingPage.validation.passwordEmpty')
            return true
         }
         return false
      },
      error: '',
   },
   password: {
      validation(data) {
         if (!data.password.trim()) {
            this.error = t('landingPage.validation.passwordEmpty')
            return true
         }
         if (data.password.length <= 5) {
            this.error = t('landingPage.validation.password')
            return true
         }
         return false
      },
      error: t('landingPage.validation.passwordEmpty'),
   },
   repeat_password: {
      validation(data) {
         if (data.newPassword) {
            return data.newPassword !== data.repeat_password
         }
         return data.password !== data.repeat_password
      },
      error: t('landingPage.validation.passwordMatch'),
   },
   oldPassword: {
      validation: () => false,
      error: '',
   },
   first_name: {
      validation(data) {
         if (validateFullName(data.first_name)) {
            // this.error = t('landingPage.validation.nameNumber')
            this.error = validateFullName(data.first_name, 'Name')
            return true
         }
         return false
      },
      error: '',
   },
   client_name: {
      validation(data) {
         if (validateFullName(data.client_name)) {
            // this.error = t('landingPage.validation.nameNumber')
            this.error = validateFullName(data.client_name, 'Client name')
            return true
         }
         // if (isStrHasNumbers(data.client_name)) {
         //    this.error = t('landingPage.validation.nameNumber')
         //    return true
         // }
         // if (!data.client_name.trim()) {
         //    this.error = t('landingPage.validation.nameEmpty')
         //    return true
         // }
         return false
      },
      error: '',
   },
   last_name: {
      validation(data) {
         console.warn('last_name', data)
         if (validateFullName(data.last_name)) {
            // this.error = t('landingPage.validation.nameNumber')
            this.error = validateFullName(data.last_name, 'Surname')
            return true
         }
         // if (isStrHasNumbers(data.last_name)) {
         //    this.error = t('landingPage.validation.lastNameNumber')
         //    return true
         // }
         return false
      },
      error: '',
   },
   product_title: {
      validation(data) {
         if (validateFullName(data.product_title)) {
            // this.error = t('landingPage.validation.nameNumber')
            this.error = validateFullName(data.product_title, 'Product title')
            return true
         }
         // if (!data.product_title.trim()) {
         //    this.error = t('landingPage.validation.titleEmpty')
         //    return true
         // }
         return false
      },
      error: '',
   },
   product_price: {
      validation(data) {
         if (!data.product_price) {
            this.error = t('landingPage.validation.priceEmpty')
            return true
         }
         return false
      },
      error: '',
   },
   service_type_id: {
      validation(data) {
         if (!data.service_type_id) {
            this.error = t('landingPage.validation.requiredField')
            return true
         }
         return false
      },
      error: '',
   },
   category_id: {
      validation(data) {
         if (!data.category_id) {
            this.error = t('landingPage.validation.requiredField')
            return true
         }
         return false
      },
      error: '',
   },
   company_name: {
      // validation: (data) => !data.company_name.trim(),
      // error: t('landingPage.validation.requiredField'),
      validation(data) {
         if (validateFullName(data.company_name)) {
            // this.error = t('landingPage.validation.nameNumber')
            this.error = validateFullName(data.company_name, 'Company name')
            return true
         }
         // if (!data.product_title.trim()) {
         //    this.error = t('landingPage.validation.titleEmpty')
         //    return true
         // }
         return false
      },
      error: '',
   },
   seller_name: {
      validation(data) {
         // if (!data.seller_name.trim()) {
         //    this.error = t('landingPage.validation.nameEmpty')
         //    return true
         // }
         // if (isStrHasNumbers(data.seller_name)) {
         //    this.error = t('landingPage.validation.nameNumber')
         //    return true
         // }
         if (validateFullName(data.seller_name)) {
            // this.error = t('landingPage.validation.nameNumber')
            this.error = validateFullName(data.seller_name, 'Seller name')
            return true
         }
         return false
      },
      error: t('landingPage.validation.nameEmpty'),
   },
   category_title: {
      validation: (data) => !data.category_title,
      error: t('landingPage.validation.titleEmpty'),
   },
   product_id: {
      validation(data) {
         if (!data.product_id) {
            this.error = t('landingPage.validation.requiredField')
            return true
         }
         return false
      },
      error: '',
   },
   type_of_pay: {
      validation(data) {
         if (!data.type_of_pay.trim()) {
            this.error = t('landingPage.validation.requiredField')
            return true
         }
         return false
      },
      error: '',
   },
   bank_account_name: {
      validation(data) {
         // if (!data.bank_account_name.trim()) {
         //    this.error = t('landingPage.validation.requiredField')
         //    return true
         // }
         if (validateFullName(data.bank_account_name)) {
            // this.error = t('landingPage.validation.nameNumber')
            this.error = validateFullName(
               data.bank_account_name,
               'Bank account name'
            )
            return true
         }
         return false
      },
      error: '',
   },
   balance: {
      validation(data) {
         if (!data.balance.trim()) {
            this.error = t('landingPage.validation.requiredField')
            return true
         }
         return false
      },
      error: '',
   },
   amount_of_money: {
      validation(data) {
         if (!data.amount_of_money.trim()) {
            this.error = t('landingPage.validation.requiredField')
            return true
         }
         return false
      },
      error: '',
   },
   invoice_title: {
      validation(data) {
         if (!data.invoice_title.trim()) {
            this.error = t('landingPage.validation.invoiceName')
            return true
         }
         return false
      },
      error: '',
   },
   end_date: {
      validation(data) {
         if (data.end_date === null) {
            this.error = t('landingPage.validation.payBy')
            return true
         }
         return false
      },
      error: '',
   },
   sum: {
      validation(data) {
         if (data.sum - data.total_pay <= 0 || data.sum === 0) {
            this.error = t('landingPage.validation.totalAmount')
            return true
         }
         return false
      },
      error: '',
   },
   client: {
      validation(data) {
         if (data.client.client_id === '') {
            if (data.tag.id === '') {
               this.error = t('landingPage.validation.toField')
               return true
            }
            return false
         }
         return false
      },
      error: '',
   },
   seller: {
      validation(data) {
         if (data.client.client_id === '') {
            this.error = t('landingPage.validation.chooseSeller')
            return true
         }
         return false
      },
      error: '',
   },
}

export const getLastItem = (arr) => arr[arr.length - 1]

export const repeatArrElement = (elem, n) => {
   return Array(n).fill(elem)
}

export const STATUS_COLORS = {
   Оплачен: '#2CA803',
   Частично: '#73BBB3',
   Неоплачен: '#44779F',
   Истек: '#D65353',
}

export const INVOICE_DEFAULT = {
   invoice_title: '',
   start_date: new Date(),
   end_date: null,
   products: [],
   description: '',
   sum: 0,
   discount: '',
   type: 'client',
   client_id: '',
   client: { client_name: '', client_id: '', seller_name: '', seller_id: '' },
   tag: { name_tag: '', id: '' },
   tag_id: '',
   rest_amount: 0,
   status: '',
   responses: [],
}

export const getAmountOfProducts = (data) => {
   const newProducts = []

   let sum = 0

   data.forEach((item) => {
      sum += +item.count_of_product * +item.product_price
      newProducts.push({
         ...item,
         totalSum: +item.count_of_product * +item.product_price,
      })
      return null
   })
   return { products: newProducts, sum }
}

export const CLIENT_DEFAULT = {
   client_id: '',
   company_name: '',
   client_name: '',
   email: '',
   phone_number: '',
   address: '',
   tags: [],
   created: new Date(),
   income: true,
   invoices: [],
   nearest_payment: null,
}

export const SEARCH_INVOICE_INIT_VALUES = {
   clientId: '',
   status: '', // id
   startDate: new Date(),
   endDate: new Date(),
   invoiceNumber: '',
}

export const getDateFromQuery = (date, queries) => {
   if (queries[date]) {
      if (queries[date] !== 'null') return new Date(queries[date])
   }
   return null
}

export const getSearchInvoiceParams = (URLParams) => {
   const params = Object.fromEntries(URLParams.entries())

   const startDate = getDateFromQuery('startDate', params)
   const endDate = getDateFromQuery('endDate', params)
   return { ...params, startDate, endDate }
}

export const TRANSACTION_INIT_VALUES = {
   status: '', // id
   startDate: new Date(),
   endDate: new Date(),
   typeOfPay: '',
   categoryId: '',
}

export const getSearchTransactionParams = (URLParams) => {
   const params = Object.fromEntries(URLParams.entries())

   const startDate = getDateFromQuery('startDate', params)
   const endDate = getDateFromQuery('endDate', params)

   return { ...params, startDate, endDate }
}

export const getDefaultParamsTransaction = (params) => {
   const updatedQueries = {
      ...TRANSACTION_INIT_VALUES,
      ...params,
      startDate: null,
      endDate: null,
      status: params.status ? params.status : '',
      typeOfPay: params.typeOfPay ? params.typeOfPay : '',
      categoryId: params.categoryId ? params.categoryId : '',
   }
   return updatedQueries
}

export const INVOICE_SEARCH_STATUSES = [
   { label: t('expenses.sellers.allStatuses'), id: 'getAll' },
   { label: t('expenses.sellers.notPaid'), id: 'NOT_PAID' },
   { label: t('expenses.sellers.partially'), id: 'PARTIALLY' },
   { label: t('expenses.sellers.expired'), id: 'EXPIRED' },
   { label: t('expenses.sellers.paid'), id: 'PAID' },
]

export const INVOICE_STATUSES = [
   {
      label: t('sales.invoice.invoiceSearch.invoiceStatuses.allStatuses'),
      id: 'getAll',
   },
   {
      label: t('sales.invoice.invoiceSearch.invoiceStatuses.notPaid'),
      id: 'NOT_PAID',
   },
   {
      label: t('sales.invoice.invoiceSearch.invoiceStatuses.partially'),
      id: 'PARTIALLY',
   },
   {
      label: t('sales.invoice.invoiceSearch.invoiceStatuses.expired'),
      id: 'EXPIRED',
   },
   { label: t('sales.invoice.invoiceSearch.invoiceStatuses.paid'), id: 'PAID' },
]

export const getSortedTransactions = (data) => {
   const frequencyCounter = {}
   data.map((item) => {
      if (!item.category_response) {
         const counter = {}
         item.response?.products.map((product) => {
            counter[product.category.category_title] =
               (counter[product.category.category_title] || 0) + 1
            return null
         })
         frequencyCounter[item.response?.invoice_title] = counter
         return null
      }
      return item
   })

   // eslint-disable-next-line consistent-return, array-callback-return
   const transactions = data.map((item) => {
      if (!item.category_response) {
         return {
            ...item,
            categories: Object.entries(
               frequencyCounter[item.response.invoice_title]
            ).map((item) => `${item[0]}`),
         }
      }
      return {
         ...item,
      }
   })
   return transactions
}

export const stringFormatter = Intl.NumberFormat('en-US')

export const checkLanguage = (status) => {
   switch (status) {
      case 'Частично':
         return t('sales.invoice.invoiceInner.partially')
      case 'Оплачен':
         return t('sales.invoice.invoiceInner.paidStatus')
      case 'Истек':
         return t('sales.invoice.invoiceInner.expired')
      case 'Неоплачен':
         return t('sales.invoice.invoiceInner.notPaid')
      default:
         return ''
   }
}
