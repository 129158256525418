import './assets/styles/App.css'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { GlobalStyles } from '@mui/material'
import { GLOBAL_STYLES } from './assets/styles/GlobalStyles'
import MainRouter from './Routes/MainRouter'
import {
   getLanguage,
   getStoragedItem,
} from './utils/helpers/localStorageHelpers'
import { userActions } from './store/user/userSlice'
import Spinner from './components/UI/Spinner/Spinner'
import { JWT_TOKEN_KEY, ROUTES, ROLE } from './utils/constants/general'

function App() {
   const dispatch = useDispatch()
   const { pathname } = useLocation()
   const isAuthorized = useSelector((state) => state.user.isAuthorized)
   const { language } = useSelector((state) => state.user)
   const [loading, setIsLoading] = useState(true)

   useEffect(() => {
      const jwtToken = getStoragedItem(JWT_TOKEN_KEY)
      if (jwtToken) {
         const role = getStoragedItem(ROLE)
         const authorizedUserCredentials = { accessToken: jwtToken, role }
         dispatch(userActions.logIn(authorizedUserCredentials))
      }
      setIsLoading(false)
   }, [])

   useEffect(() => {
      const language = getLanguage('i18nextLng')
      dispatch(userActions.setLanguage(language || 'en-GB'))
   }, [language])

   if (loading) return <Spinner size={55} />
   if (pathname === '/') return <Navigate to={ROUTES['MAIN-PAGE']} replace />

   return (
      <div className="App">
         <GlobalStyles styles={GLOBAL_STYLES} />
         <MainRouter isAuthorized={isAuthorized} />
      </div>
   )
}

export default App
