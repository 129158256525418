import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   reminder: { reminder: [], expired: [], requests: [] },
   isLoading: true,
}
const reminderSlice = createSlice({
   name: 'reminderSlice',
   initialState,
   reducers: {
      setIsLoading(state, { payload }) {
         state.isLoading = payload
      },
      setReminder(state, { payload }) {
         state.reminder = payload.data
      },
   },
})
export const reminderActions = reminderSlice.actions

export default reminderSlice
