import { createSlice } from '@reduxjs/toolkit'

const url = new URLSearchParams(window.location.search)

const getFromUrl = (query) => JSON.parse(url.get(query))

const initialState = {
   isReg: getFromUrl('showReg') || false,
   isLogin: getFromUrl('showLogin') || false,
}

const landingSlice = createSlice({
   name: 'landingSlice',
   initialState,
   reducers: {
      setLogin(state, { payload }) {
         state.isLogin = payload
      },
      setReg(state, { payload }) {
         state.isReg = payload
      },
   },
})

export const landingActions = landingSlice.actions

export default landingSlice
