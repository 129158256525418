import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   pie: [],
   line: [],
   table: {},
   isLoading: false,
}

const chartSlice = createSlice({
   name: 'chartSlice',
   initialState,
   reducers: {
      setIsLoading(state, { payload }) {
         state.isLoading = payload
      },
      setCharts(state, { payload }) {
         state.pie = payload.pie || []
         state.line = payload.line
         state.isLoading = false
      },
      setPieChart(state, { payload }) {
         state.pie = payload.pie
      },
      setTable(state, { payload }) {
         state.table = payload.data
      },
   },
})

export const chartActions = chartSlice.actions

export default chartSlice
