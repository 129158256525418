import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   expenseProducts: [],
   totalPage: 1,
   isLoading: false,
}

const expenseProductSlice = createSlice({
   name: 'expenseProductSlice',
   initialState,
   reducers: {
      setProducts(state, { payload }) {
         state.expenseProducts = payload.expenseProducts
         state.totalPage = payload.totalPage
         state.isLoading = false
      },
      setIsLoading(state, { payload }) {
         state.isLoading = payload
      },
   },
})

export const expenseProductActions = expenseProductSlice.actions

export default expenseProductSlice
