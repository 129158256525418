import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   invoices: [],
   requests: { content: [], totalPages: 0 },
   totalPage: 1,
   isLoading: false,
   sum: 0,
   overDuedSum: 0,
   all_payment: 0,
   all_unpaid_sum: 0,
   not_expired: 0,
   notPaidSum: 0,
   expired: 0,
   countOfInvoice: 0,
   nearest_invoice_payment_date: '',
   load: false,
}

const invoiceSlice = createSlice({
   name: 'invoiceSlice',
   initialState,
   reducers: {
      setInvoices(state, { payload }) {
         state.invoices = payload.invoices
         state.totalPage = payload.totalPage
         state.isLoading = false
         state.overDuedSum = payload.overDuedSum
         state.sum = payload.sum || 0
         state.all_unpaid_sum = payload.all_unpaid_sum || 0
         state.expired = payload.expired || 0
         state.countOfInvoice = payload.countOfInvoice || 0
      },
      setClientInvoices(state, { payload }) {
         state.invoices = payload.invoices
         state.all_payment = payload.all_payment
         state.all_unpaid_sum = payload.all_unpaid_sum
         state.expired = payload.expired
         state.not_expired = payload.not_expired
         state.unpaid_amount = payload.unpaid_amount || 0
         state.nearest_invoice_payment_date =
            payload.nearest_invoice_payment_date
      },
      setIsLoading(state, { payload }) {
         state.isLoading = payload
      },
      setRequest(state, { payload }) {
         state.requests = payload
      },
      setLoad(state, { payload }) {
         state.load = payload
      },
   },
})

export const invoiceActions = invoiceSlice.actions

export default invoiceSlice
